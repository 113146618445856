import googleAnalytics from './../google-analytics';

const buildPromotionForTracking = (promotion, position, creative) => {
    const promo = {
        id: promotion.image.title,
        name: promotion.image.title,
        creative: creative,
        position: position
    };
    return promo;
}

const trackBanners = (promotions, event, titleTracking) => {
    const ecommerceData = {};
    ecommerceData[titleTracking] = {
        promotions: promotions
    }
    googleAnalytics.trackEcommerce(event, ecommerceData);
}


export default {
    trackBanners,
    buildPromotionForTracking
}