import React from 'react';
import helper from 'shared/metrics/imettrics/helper'

export default function tracking(WrapperComponent) {
	return class extends React.Component {

        impression(data) {
            try {
                let promotions = [];

                for (let i = 0; i < data.length; i++) {
                    let banner = {};
                    let position = i + 1;
                    let alt = data[i].imageAlt;

                    banner.id = `${alt}-${position}`;
                    banner.name = alt;
                    banner.creative = 'omnibus-home';
                    banner.position = `promocionCentral-${position}`;
                    promotions.push(banner);
                }

                helper.trackBanners(promotions, "promotionImpression", "promoView");
            } catch (e) { }
        }

		render () {
			const props = {
				tracking: {
					impression: this.impression
				},
				...this.props
			};
    
			return (
                <WrapperComponent {...props} />
			);
		}
	}
}