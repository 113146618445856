import React, { useEffect } from 'react';

import Deal from 'components/shared/deals/deal';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import tracking  from './tracking';

const Deals = ({ customClasses, serverSide, data:{ deals}, tracking, children }) => {
  useStyles(classes);
  useEffect(() => {
    tracking.impression(deals);
  }, [])

  return (
    <div className={classes.CytResultsContainer} >
        <div className={`${classes.bannerContainer} ${customClasses.bannerContainer}`}>
        {
          deals.map((deal, index) => {
            const {link, imageAlt, image} = deal;

            return (
              <Deal 
                link={link} 
                alt={imageAlt} 
                image={image} 
                key={index}
                position={index + 1}
                customClasses={customClasses}
                serverSide={serverSide}
              >
                { children[index] }
              </Deal>
            )
          })
        }
      </div>
    </div>
  )
}

Deals.defaultProps = {
  customClasses:{
    bannerContainer:''
  },
  serverSide: false
};

export default tracking(Deals);